import * as React from 'react';
import styled from '../../styled-components';
import { colourCycle } from '../../utilities';
import { keyframes } from 'styled-components';

interface IScrollPromptProps {
    href?: string;
}

const fadeArrows = keyframes`
    0% {
        opacity: 0
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const ScrollDownPromptStyles = styled.a`
    padding: 0;
    background: none;
    border: none;

    .arrow {
        opacity: 0;
        animation-name: ${fadeArrows}, ${colourCycle};
        animation-duration: 3s, 9s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        fill: ${({ theme }) => theme.colors.green.color};
    }

    #arrow-1 {
        animation-delay: 0;
    }

    #arrow-2 {
        animation-delay: 0.5s, 0.5s;
    }

    #arrow-3 {
        animation-delay: 1s, 1s;
    }
`;

function scrollToTarget(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    const target = event.currentTarget.hash;

    if (target && document.querySelector(target)) {
        document.querySelector(target).scrollIntoView({
            behavior: 'smooth'
        });
    }
}

const ScrollDownPrompt: React.SFC<IScrollPromptProps> = ({ href }) => (
    <ScrollDownPromptStyles
        href={href}
        onClick={scrollToTarget}
        aria-label="Scroll down"
    >
        <svg width="36px" height="33px" viewBox="0 0 36 33" version="1.1">
            <g id="arrows">
                <path
                    d="M17.8646828,27.8579564 L33.9758809,20 L35.7293655,23.5951762 L17.8761674,32.3156671 L17.8748756,32.3181404 L-7.41030501e-15,23.5951762 L1.75348459,20 L17.8646828,27.8579564 Z"
                    id="arrow-3"
                    className="arrow"
                />
                <path
                    d="M17.8646828,17.8579564 L33.9758809,10 L35.7293655,13.5951762 L17.8761674,22.3156671 L17.8748756,22.3181404 L-7.41030501e-15,13.5951762 L1.75348459,10 L17.8646828,17.8579564 Z"
                    id="arrow-2"
                    className="arrow"
                />
                <path
                    d="M17.8646828,7.85795639 L33.9758809,4.4408921e-15 L35.7293655,3.59517619 L17.8761674,12.3156671 L17.8748756,12.3181404 L-7.41030501e-15,3.59517619 L1.75348459,4.4408921e-16 L17.8646828,7.85795639 Z"
                    id="arrow-1"
                    className="arrow"
                />
            </g>
        </svg>
    </ScrollDownPromptStyles>
);

export default ScrollDownPrompt;
