import React from 'react';
import styled, { css } from '../../styled-components';

interface IHamburgerProps extends wmw.IBaseStyledComponentProps {
    open: boolean;
    color: string;
}
const UnstyledHamburger: React.FC<IHamburgerProps> = ({ className }) => (
    <div className={className}>
        <span />
        <span />
        <span />
    </div>
);

export const Hamburger = styled(UnstyledHamburger)`
    width: 30px;
    height: 22px;
    position: relative;
    transform: rotate(0deg);
    transition: transform 500ms ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: ${({ color }) => color};
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 250ms ease-in-out;

        ${({ color, theme }) =>
            color === 'white' &&
            css`
                box-shadow: 0px 0px 4px ${theme.colors.deepBlue.color};
            `};
    }

    & span:nth-child(1) {
        top: 0px;
        transform-origin: left center;
    }

    & span:nth-child(2) {
        top: calc(50% - 1px);
        transform-origin: left center;
    }

    & span:nth-child(3) {
        top: calc(100% - 2px);
        transform-origin: left center;
    }

    ${({ open }) =>
        open &&
        css`
            span:nth-child(1) {
                transform: rotate(45deg);
                top: 1px;
                left: 3px;
            }

            span:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
                top: 100%;
                left: 3px;
            }
        `}
`;
