import styled from '../../styled-components';
import backgroundSmall from '../../images/deep-sea-blue--small.jpg';
import backgroundMedium from '../../images/deep-sea-blue--medium.jpg';
import backgroundLarge from '../../images/deep-sea-blue--large.jpg';
import { media, breakpoints } from '../../utilities';

export default styled.div`
    position: relative;
    height: 87vh;
    background-size: cover;
    color: white;
    will-change: transform;

    background-color: #001c25;
    background-image: url(${backgroundSmall});

    ${media.m`
        height: 100vh;
        background-image: url(${backgroundMedium});
    `}

    ${media.l`
        background-image: url(${backgroundLarge});
    `}
`;
