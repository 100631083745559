import * as React from 'react';
import styled from '../../styled-components';
import theme from '../../theme';
import { pixelToRem, fadeIn, media } from '../../utilities';
import Wordmark from '../../images/WMW_Wordmark.svg';

import 'web-animations-js';

const ANIMATION_DELAY = 250;
export const ANIMATION_DURATION = 2500;

const Logo = styled.svg`
    overflow: visible;
    width: 100%;
    display: block;
    position: relative;
    z-index: 1000;
    transform: translateZ(0);
    @supports (display: block) {
        /*
        This is for SVG sizing in IE 11
        > IE11 does not support supports, so just need to put anything in here
        */
        height: auto;
    }
`;

const HiddenGroup = styled.g`
    opacity: 0;
`;

const AnimatingLogoGroup = styled.g`
    fill: ${theme.colors.electricBlue.color};
`;

const LogoGroup = styled(AnimatingLogoGroup)`
    @supports (animation-name: ${fadeIn}) {
        opacity: 0;

        animation: ${fadeIn} 300ms ease-out ${ANIMATION_DELAY + 1000}ms forwards;
    }
`;

const SlashGroup = styled.g`
    fill: ${theme.colors.white.color};

    > *:nth-child(2) {
        fill: ${theme.colors.electricBlue.color};
    }

    > *:nth-child(3) {
        fill: ${theme.colors.green.color};
    }

    > *:nth-child(4) {
        fill: ${theme.colors.orange.color};
    }

    > *:nth-child(5) {
        fill: ${theme.colors.pink.color};
    }
`;

const LogoWordmark = styled.div`
    margin-top: ${pixelToRem(25)};
    text-align: center;

    @supports (animation-name: ${fadeIn}) {
        opacity: 0;
        margin-bottom: 0;
        animation: ${fadeIn} 300ms ease-out ${ANIMATION_DURATION + 300}ms
            forwards;
    }

    html.no-js {
        opacity: 1;
    }

    img {
        width: 80%;
    }

    ${media.m`
        margin-top: ${pixelToRem(40)};
    `}
`;

interface IAnimatedLogoProps {
    triggerAnimation: boolean;
    animationCompleteCb: () => void;
    showWordMark?: boolean;
    className?: string;
}

class UnstyledAnimatedLogo extends React.Component<IAnimatedLogoProps> {
    private slashRefs: Array<React.RefObject<SVGGElement>> = [
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>()
    ];

    private sectionRefs: Array<React.RefObject<SVGGElement>> = [
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>(),
        React.createRef<SVGGElement>()
    ];

    private logoContainerRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    private animatingLogoRef: React.RefObject<SVGGElement> = React.createRef<
        SVGGElement
    >();
    private logoRef: React.RefObject<SVGGElement> = React.createRef<
        SVGGElement
    >();
    private workmarkRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    public componentDidMount() {
        this.logoContainerRef.current.style.opacity = '1';

        if (!this.isAnimationSupported()) {
            this.props.animationCompleteCb();
            return; // Web animations api not supported
        }

        this.resetStylesReadyForAnimation();
    }

    public shouldComponentUpdate({ triggerAnimation }: IAnimatedLogoProps) {
        if (
            triggerAnimation &&
            !this.props.triggerAnimation &&
            this.isAnimationSupported()
        ) {
            this.triggerAnimation();
        }

        return true;
    }

    public componentDidUpdate({
        triggerAnimation,
        showWordMark
    }: IAnimatedLogoProps) {
        // Reduce wordmark delay if user has been scrolling and has seen animation
        if (this.props.showWordMark && triggerAnimation && !showWordMark) {
            this.workmarkRef.current.style.animationDelay = `${100}ms`;
        }
    }

    public render() {
        const { showWordMark, triggerAnimation } = this.props;

        return (
            <div className={this.props.className} ref={this.logoContainerRef}>
                <Logo
                    viewBox="0 0 530 208"
                    version="1.1"
                    width="700"
                    height="275"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <LogoGroup ref={this.logoRef}>
                        <polygon points="529.76 0.19 505.59 0.19 442.27 174.16 435.36 155.18 491.78 0.19 467.61 0.19 423.28 121.98 416.37 103.01 453.8 0.19 429.63 0.19 404.29 69.81 378.95 0.19 378.95 0.19 378.95 0.19 361.69 0.19 359.96 4.94 352.37 25.81 342.01 54.27 334.41 75.14 324.05 103.6 291.12 194.08 286.29 207.36 295.95 207.36 303.55 207.36 332.68 127.31 358.02 196.92 361.82 207.36 378.39 207.36 382.54 207.36 380.46 201.67 368.38 168.46 343.04 98.85 350.64 77.98 375.98 147.59 388.06 180.8 394.97 199.77 397.73 207.36 416.37 207.36 418.45 207.36 417.41 204.51 405.32 171.31 398.42 152.34 386.33 119.14 361 49.52 368.59 28.65 393.93 98.26 406.02 131.47 412.92 150.44 425.01 183.64 431.91 202.62 433.64 207.36 454.35 207.36 454.35 207.36 454.35 207.36" />
                        <polygon points="326.12 52.37 333.72 31.5 344.08 3.04 345.11 0.19 343.04 0.19 327.85 0.19 325.09 7.78 317.49 28.65 307.13 57.11 274.2 147.59 267.3 166.57 258.32 191.23 252.45 207.36 264.19 207.36 269.71 207.36 275.93 190.28 282.83 171.31 315.77 80.83" />
                        <path d="M265.91,124.83 L298.84,34.35 L309.2,5.89 L311.27,0.2 L307.13,0.2 L294.01,0.2 L290.21,10.64 L257.28,101.12 L220.55,0.19 L212.95,0.19 L206.73,0.19 L209.84,8.73 L250.37,120.08 L241.39,144.74 L200.87,33.4 L195.69,19.17 L188.78,0.2 L183.26,0.2 L174.97,0.2 L179.11,11.58 L188.78,38.14 L193.96,52.37 L234.49,163.72 L225.51,188.38 L184.99,77.03 L179.81,62.8 L170.14,36.24 L164.96,22.01 L157.02,0.19 L153.57,0.19 L143.21,0.19 L102.68,111.54 L62.15,0.19 L55.24,0.19 L99.22,121.03 L104.4,135.26 L114.07,161.82 L119.25,176.05 L128.92,202.61 L130.65,207.35 L137.55,207.35 L178.08,96 L218.61,207.35 L232.42,207.35 L235.87,207.35 L243.12,187.43 L250.03,168.46 L259.01,143.8 L265.91,124.83 Z M107.86,125.78 L148.39,14.43 L158.06,40.99 L117.53,152.34 L107.86,125.78 Z M132.37,193.13 L122.7,166.57 L163.23,55.22 L172.9,81.78 L132.37,193.13 Z" />
                        <polygon points="103.72 190.28 94.05 163.72 88.87 149.49 34.53 0.19 27.62 0.19 85.42 158.98 90.6 173.21 100.26 199.77 103.03 207.36 107.86 207.36 109.93 207.36 108.89 204.51" />
                        <polygon points="75.06 187.44 6.91 0.19 0 0.19 71.6 196.92 75.4 207.36 78.16 207.36 82.31 207.36 80.24 201.67" />
                    </LogoGroup>

                    <AnimatingLogoGroup ref={this.animatingLogoRef}>
                        <HiddenGroup
                            transform="translate(0.000000, 0.000000)"
                            ref={this.sectionRefs[0]}
                        >
                            <polygon points="103.72 190.28 94.05 163.72 88.87 149.49 34.53 0.19 27.62 0.19 85.42 158.98 90.6 173.21 100.26 199.77 103.03 207.36 107.86 207.36 109.93 207.36 108.89 204.51" />
                            <polygon points="131.72 190.28 122.05 163.72 116.87 149.49 62.53 0.19 55.62 0.19 113.42 158.98 118.6 173.21 128.26 199.77 131.03 207.36 135.86 207.36 137.93 207.36 136.89 204.51" />
                            <polygon points="6.91 0.19 0 0.19 71.6 196.92 75.4 207.36 78.16 207.36 82.31 207.36 80.24 201.67" />
                        </HiddenGroup>

                        <HiddenGroup
                            id="second"
                            transform="translate(102.000000, 0.000000)"
                            fillRule="nonzero"
                            ref={this.sectionRefs[1]}
                        >
                            <polygon points="41.21 0.19 0.67 111.54 5.85113316 125.775858 46.3937968 14.3885938 46.420105 14.3075562 41.394931 0.2" />
                            <polygon points="15.5289443 152.339465 20.7034129 166.557011 61.2352938 55.2121795 61.2575226 55.1589508 56.0848694 40.9406738 56.0615359 40.996586" />
                            <polygon points="30.3709237 193.119794 35.55 207.35 76.08 96 76.1001129 95.9391785 70.9268799 81.7270355 70.9046432 81.7800133" />
                        </HiddenGroup>

                        <HiddenGroup
                            id="third"
                            transform="translate(143.000000, 0.000000)"
                            fillRule="nonzero"
                            ref={this.sectionRefs[2]}
                        >
                            <path
                                d="M114.28,101.12 L77.55,0.19 L69.95,0.19 L63.73,0.19 L66.84,8.73 L107.37,120.08 L98.39,144.74 L57.87,33.4 L52.69,19.17 L45.78,0.2 L40.26,0.2 L31.97,0.2 L36.11,11.58 L45.78,38.14 L50.96,52.37 L91.5166321,163.80426 L82.5715179,188.574219 L41.99,77.03 L36.81,62.8 L27.14,36.24 L21.96,22.01 L14.02,0.19 L10.57,0.19 L0.21,0.19 L35.08,96 L75.61,207.35 C75.6303621,207.357527 75.6419234,207.357527 75.6446838,207.35 C78.2657233,200.20335 80.5881792,194.041607 82.5715179,188.574219 C84.5579899,183.098193 87.5396946,174.84154 91.5166321,163.80426 L98.423516,144.829917 L98.39,144.74 L107.37,120.08 L107.412674,120.229368 L114.329306,101.281863 L114.28,101.12 Z"
                                id="Shape-path"
                            />
                        </HiddenGroup>

                        <HiddenGroup
                            id="fourth"
                            transform="translate(218.000000, 0.000000)"
                            ref={this.sectionRefs[3]}
                        >
                            <polygon points="160.95 0.19 143.69 0.19 141.96 4.94 134.37 25.81 124.01 54.27 116.41 75.14 106.05 103.6 73.12 194.08 68.29 207.36 77.95 207.36 85.55 207.36 114.68 127.31" />
                            <polygon points="108.12 52.37 115.72 31.5 126.08 3.04 127.11 0.19 125.04 0.19 109.85 0.19 107.09 7.78 99.49 28.65 89.13 57.11 56.2 147.59 49.3 166.57 40.32 191.23 34.45 207.36 46.19 207.36 51.71 207.36 57.93 190.28 64.83 171.31 97.77 80.83" />
                            <polygon points="47.91 124.83 80.84 34.35 91.2 5.89 93.27 0.2 89.13 0.2 76.01 0.2 72.21 10.64 39.28 101.12 0.61 207.35 14.42 207.35 17.87 207.35 25.12 187.43 32.03 168.46 41.01 143.8" />
                        </HiddenGroup>

                        <HiddenGroup
                            id="fifth"
                            transform="translate(324.000000, 0.000000)"
                            ref={this.sectionRefs[4]}
                        >
                            <polygon points="28.37 25.81 18.01 54.27 26.64 77.98 51.98 147.59 64.06 180.8 70.97 199.77 73.73 207.36 92.37 207.36 94.45 207.36 93.41 204.51 81.32 171.31 74.42 152.34 62.33 119.14 37 49.52" />
                            <polygon points="118.27 174.16 111.36 155.18 99.28 121.98 92.37 103.01 80.29 69.81 54.95 0.19 37.69 0.19 35.96 4.94 44.59 28.65 69.93 98.26 82.02 131.47 88.92 150.44 101.01 183.64 107.91 202.62 109.64 207.36 130.35 207.36" />
                            <polygon points="10.41 75.14 0.05 103.6 8.68 127.31 34.02 196.92 37.82 207.36 54.39 207.36 58.54 207.36 56.46 201.67 44.38 168.46 19.04 98.85" />
                        </HiddenGroup>

                        <HiddenGroup
                            id="sixth"
                            transform="translate(404.000000, 0.000000)"
                            ref={this.sectionRefs[5]}
                        >
                            <polygon points="125.76 0.19 101.59 0.19 38.27 174.16 38.1180994 174.619163 50.1519675 207.393431 50.35 207.36" />
                            <polygon points="31.36 155.18 87.78 0.19 63.61 0.19 19.28 121.98 19.0941223 122.481211 31.1825751 155.635032" />
                            <polygon points="12.37 103.01 49.8 0.19 25.63 0.19 0.29 69.81 0.113650317 70.3261818 12.1671364 103.549631" />
                        </HiddenGroup>
                    </AnimatingLogoGroup>

                    <SlashGroup>
                        <g transform="translate(0.000000, 0.000000)">
                            <HiddenGroup
                                ref={this.slashRefs[0]}
                                data-animation-type="backward"
                            >
                                <polygon points="103.72 190.28 94.05 163.72 88.87 149.49 34.53 0.19 27.62 0.19 85.42 158.98 90.6 173.21 100.26 199.77 103.03 207.36 107.86 207.36 109.93 207.36 108.89 204.51" />
                                <polygon points="131.72 190.28 122.05 163.72 116.87 149.49 62.53 0.19 55.62 0.19 113.42 158.98 118.6 173.21 128.26 199.77 131.03 207.36 135.86 207.36 137.93 207.36 136.89 204.51" />
                                <polygon points="6.91 0.19 0 0.19 71.6 196.92 75.4 207.36 78.16 207.36 82.31 207.36 80.24 201.67" />
                            </HiddenGroup>
                        </g>

                        <g
                            transform="translate(102.000000, 0.000000)"
                            fillRule="nonzero"
                        >
                            <HiddenGroup
                                ref={this.slashRefs[1]}
                                data-animation-type="forward"
                            >
                                <polygon points="15.5289443 152.339465 20.7034129 166.557011 61.2352938 55.2121795 56.0615359 40.996586" />
                                <polygon points="41.21 0.19 0.67 111.54 5.85113316 125.775858 46.3937968 14.3885938" />
                                <polygon points="30.3709237 193.119794 35.55 207.35 76.08 96 70.9046432 81.7800133" />
                            </HiddenGroup>
                        </g>
                        <g
                            transform="translate(143.000000, 0.000000)"
                            fillRule="nonzero"
                        >
                            <HiddenGroup
                                ref={this.slashRefs[2]}
                                data-animation-type="backward"
                            >
                                <polygon points="114.28 101.12 77.55 0.19 69.95 0.19 63.73 0.19 66.84 8.73 107.37 120.08 98.39 144.74 57.87 33.4 52.69 19.17 45.78 0.2 40.26 0.2 31.97 0.2 36.11 11.58 45.78 38.14 50.96 52.37 91.49 163.72 82.51 188.38 41.99 77.03 36.81 62.8 27.14 36.24 21.96 22.01 14.02 0.19 10.57 0.19 0.21 0.19 35.08 96 75.61 207.35" />
                            </HiddenGroup>
                        </g>
                        <g transform="translate(218.000000, 0.000000)">
                            <HiddenGroup
                                ref={this.slashRefs[3]}
                                data-animation-type="forward"
                            >
                                <polygon points="160.95 0.19 143.69 0.19 141.96 4.94 134.37 25.81 124.01 54.27 116.41 75.14 106.05 103.6 73.12 194.08 68.29 207.36 77.95 207.36 85.55 207.36 114.68 127.31" />
                                <polygon points="108.12 52.37 115.72 31.5 126.08 3.04 127.11 0.19 125.04 0.19 109.85 0.19 107.09 7.78 99.49 28.65 89.13 57.11 56.2 147.59 49.3 166.57 40.32 191.23 34.45 207.36 46.19 207.36 51.71 207.36 57.93 190.28 64.83 171.31 97.77 80.83" />
                                <polygon points="47.91 124.83 80.84 34.35 91.2 5.89 93.27 0.2 89.13 0.2 76.01 0.2 72.21 10.64 39.28 101.12 0.61 207.35 14.42 207.35 17.87 207.35 25.12 187.43 32.03 168.46 41.01 143.8" />
                            </HiddenGroup>
                        </g>
                        <g transform="translate(324.000000, 0.000000)">
                            <HiddenGroup
                                ref={this.slashRefs[4]}
                                data-animation-type="backward"
                            >
                                <polygon points="28.37 25.81 18.01 54.27 26.64 77.98 51.98 147.59 64.06 180.8 70.97 199.77 73.73 207.36 92.37 207.36 94.45 207.36 93.41 204.51 81.32 171.31 74.42 152.34 62.33 119.14 37 49.52" />
                                <polygon points="118.27 174.16 111.36 155.18 99.28 121.98 92.37 103.01 80.29 69.81 54.95 0.19 37.69 0.19 35.96 4.94 44.59 28.65 69.93 98.26 82.02 131.47 88.92 150.44 101.01 183.64 107.91 202.62 109.64 207.36 130.35 207.36" />
                                <polygon points="10.41 75.14 0.05 103.6 8.68 127.31 34.02 196.92 37.82 207.36 54.39 207.36 58.54 207.36 56.46 201.67 44.38 168.46 19.04 98.85" />
                            </HiddenGroup>
                        </g>
                        <g transform="translate(404.000000, 0.000000)">
                            <HiddenGroup
                                ref={this.slashRefs[5]}
                                data-animation-type="forward"
                            >
                                <polygon points="125.76 0.19 101.59 0.19 38.27 174.16 50.35 207.36" />
                                <polygon points="31.36 155.18 87.78 0.19 63.61 0.19 19.28 121.98" />
                                <polygon points="12.37 103.01 49.8 0.19 25.63 0.19 0.29 69.81" />
                            </HiddenGroup>
                        </g>
                    </SlashGroup>
                </Logo>

                {triggerAnimation && showWordMark && (
                    <LogoWordmark
                        ref={this.workmarkRef}
                        style={{
                            visibility: showWordMark ? 'visible' : 'hidden'
                        }}
                    >
                        <img src={Wordmark} alt="We Make Waves" />
                    </LogoWordmark>
                )}
            </div>
        );
    }

    private getKeyframes(animationType: string): any {
        const HEIGHT = window.innerHeight / 1.8;
        const HEIGHT_MULTIPLIER = 2.75;

        return animationType === 'forward'
            ? [
                  {
                      opacity: 1,
                      transform: `translate(-${HEIGHT}px, ${HEIGHT *
                          HEIGHT_MULTIPLIER}px)`
                  },
                  {
                      opacity: 1,
                      transform: `translate(${HEIGHT}px, -${HEIGHT *
                          HEIGHT_MULTIPLIER}px)`
                  }
              ]
            : [
                  {
                      opacity: 1,
                      transform: `translate(-${HEIGHT}px, -${HEIGHT *
                          HEIGHT_MULTIPLIER}px)`
                  },
                  {
                      opacity: 1,
                      transform: `translate(${HEIGHT}px, ${HEIGHT *
                          HEIGHT_MULTIPLIER}px)`
                  }
              ];
    }

    private resetStylesReadyForAnimation(): void {
        this.animatingLogoRef.current.style.opacity = '1';
        this.logoRef.current.style.opacity = '0';
        /* know this isn't correct but only way to clear a previously set animation */
        this.logoRef.current.style.animation = 'none';
    }

    private triggerAnimation() {
        const tickTime = ANIMATION_DURATION / this.slashRefs.length;

        this.slashRefs.forEach((slashRef, index) => {
            const node = slashRef.current;
            const animationType = node.getAttribute('data-animation-type');
            const delayTickTime = tickTime - (index - 1) * 30;

            const animation = node.animate(this.getKeyframes(animationType), {
                delay: ANIMATION_DELAY + index * delayTickTime,
                duration: tickTime,
                easing: 'cubic-bezier(.2,.6,.8,.4)',
                fill: 'forwards'
            });

            animation.onfinish = () => {
                node.style.display = 'none';
            };
        });

        const animationPromises = this.sectionRefs.map((slashRef, index) => {
            return new Promise(resolve => {
                const node = slashRef.current;
                const delayTickTime = tickTime - (index - 1) * 30;

                const animation = node.animate(
                    [{ opacity: 0 }, { opacity: 1 }] as any,
                    {
                        delay:
                            ANIMATION_DELAY +
                            index * delayTickTime +
                            tickTime / 2,
                        duration: 1,
                        fill: 'forwards'
                    }
                );
                animation.onfinish = resolve;
            });
        });

        Promise.all(animationPromises).then(() => {
            if (this.animatingLogoRef.current && this.logoRef.current) {
                this.animatingLogoRef.current.style.opacity = '0';
                this.logoRef.current.style.opacity = '1';

                if (this.workmarkRef.current) {
                    this.workmarkRef.current.addEventListener(
                        'animationend',
                        () => this.props.animationCompleteCb()
                    );
                } else {
                    this.props.animationCompleteCb();
                }
            }
        });
    }

    private isAnimationSupported() {
        return 'animate' in document.body;
    }
}

export default styled(UnstyledAnimatedLogo)`
    width: 100%;
    max-width: ${pixelToRem(700)};

    /* we'll unset this when the component is mounted */
    opacity: 0;

    html.no-js & {
        opacity: 1;
    }
`;
