import * as React from 'react';
import DeepSeaPanel from '../Panel/DeepSeaPanel.component';
import AnimatedLogo, {
    ANIMATION_DURATION
} from '../AnimatedLogo/AnimatedLogo.component';
import ScrollPrompt from '../ScrollPrompt/ScrollPrompt.component';
import styled, { keyframes, css } from '../../styled-components';
import { pixelToRem, media, breakpoints } from '../../utilities';

import backgroundSmall from '../../images/deep-sea-blue--small.jpg';
import backgroundMedium from '../../images/deep-sea-blue--medium.jpg';
import backgroundLarge from '../../images/deep-sea-blue--large.jpg';
import Navigation, { ToggleMenu } from '../Navigation/Navigation.component';
import { WMWTheme } from '../../theme';

export const MastheadBar = styled.div`
    width: 100%;
    height: 100px;
    background: #fff;

    @media (max-width: ${breakpoints.m}px), (max-height: 900px) {
        height: 70px;
    }
`;

export const MastheadWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    will-change: transform;
    overflow: hidden;
    z-index: 20;

    transition: box-shadow 200ms;

    html.no-js & {
        position: static;
    }
`;

export const scrollDownFadeUp = keyframes`
    from {
        opacity: 0;
        transform: translate(-50%, 100%) scale(.9);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0%) scale(1);;
    }
`;

const ScrollPromptWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);

    ${media.l`
        bottom: 3%;
    `}

    @supports (animation-name: ${scrollDownFadeUp}) {
        opacity: 0;
        animation: ${scrollDownFadeUp} 500ms ease-out forwards;
    }
`;

const SpacerPanel = styled.div`
    height: 87vh;
    margin-bottom: 100px;

    ${media.m`
        height: 100vh;
    `}

    @media (max-width: ${breakpoints.m}px), (max-height: 900px) {
        margin-bottom: 70px;
    }

    html.no-js & {
        display: none;
    }
`;

const LOGO_HEIGHT_ASPECT_RATIO = 1.842;

/* We use these to declare the minimum heights the logo should be at small and medium sized breakpoints */
/* tslint:disable:object-literal-sort-keys */
export const MASTHEAD_LOGO_HEIGHTS = {
    s: 40,
    m: 60
};

interface ILogoTranslateWrapperProps {
    scaleBy: number;
    translate: number;
    finalPosition: boolean;
}

const LogoTranslateWrapper = styled.div.attrs<ILogoTranslateWrapperProps>(
    (props: ILogoTranslateWrapperProps) => ({
        style: {
            transform: `scale(${
                props.scaleBy
            }) translate(-50%, ${props.translate / props.scaleBy}px)`,
            willChange: `${props.scaleBy === 0.22 ? 'unset' : 'transform'}`
        }
    })
)<any>`
    position: absolute;
    width: calc(100% - 100px);
    max-width: ${pixelToRem(700)};
    left: 50%;
    transform: translateY(50%);
    transform-origin: left;
    overflow: visible;

    html.no-js &[style] {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    svg g {
        transition: fill 0.5s ease;
        fill: ${(props: ILogoTranslateWrapperProps & { theme: WMWTheme }) =>
            props.finalPosition
                ? props.theme.colors.deepBlue.color
                : ''} !important;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const NavigationFadeIn = styled.div<{ showNavigation: boolean }>`
    @supports (animation-name: ${fadeIn}) {
        nav,
        ${ToggleMenu} {
            opacity: ${({ showNavigation }) => (showNavigation ? '1' : '0')};
            transition: opacity 500ms;
        }
    }
`;

interface IMastheadState {
    finished: boolean;
    logoScale: number;
    logoTranslate: number;
    panelTranslate: number;
    scrolling: boolean;
    hasScrolled: boolean;
    animationComplete: boolean;
    logoAnimationFlag: boolean;
}

export default class Masthead extends React.Component<{}, IMastheadState> {
    public state = {
        finished: false,
        logoScale: 1,
        logoTranslate: 0,
        panelTranslate: 0,
        scrolling: false,
        hasScrolled: false,
        animationComplete: false,
        logoAnimationFlag: false
    };

    private mastheadRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    private mastheadBarRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    private mainLogoRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    private deepSeaPanelRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    private mastheadHeight: number;
    private mastheadBarHeight: number;
    private logoHeight: number;
    private logoHeightWithoutWordmark: number;
    private deepSeaPanelHeight: number;
    private logoStartPosition: number;
    private logoEndPosition: number;
    private minLogoHeight: number;

    public componentDidMount() {
        this.setPositions();

        this.updatePositionsBasedOnScroll();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.refreshPositions);

        this.showLogoWhenMounted();
        this.preloadBackgroundImageForAnimation();
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.refreshPositions);
    }

    public render() {
        const showWordMark = !this.state.scrolling;
        return (
            <>
                <NavigationFadeIn
                    showNavigation={
                        this.state.hasScrolled || this.state.animationComplete
                    }
                >
                    <Navigation reverse={this.state.finished} />
                </NavigationFadeIn>

                <MastheadWrapper
                    ref={this.mastheadRef}
                    style={{
                        boxShadow: `${
                            this.state.finished
                                ? '0 1px 1px 0 rgba(157,157,157,0.2)'
                                : '0 0 0 0 rgba(157,157,157,0.2)'
                        }`,
                        transform: `translateY(-${this.state.panelTranslate}px)`
                    }}
                >
                    <DeepSeaPanel ref={this.deepSeaPanelRef}>
                        <LogoTranslateWrapper
                            translate={this.state.logoTranslate}
                            scaleBy={this.state.logoScale}
                            finalPosition={
                                this.state.logoTranslate ===
                                this.logoEndPosition
                            }
                            ref={this.mainLogoRef}
                        >
                            <AnimatedLogo
                                showWordMark={showWordMark}
                                animationCompleteCb={
                                    this.setAnimationAsComplete
                                }
                                triggerAnimation={this.state.logoAnimationFlag}
                            />
                        </LogoTranslateWrapper>

                        {showWordMark && this.state.animationComplete && (
                            <ScrollPromptWrapper>
                                <ScrollPrompt href="#what-we-do" />
                            </ScrollPromptWrapper>
                        )}
                    </DeepSeaPanel>

                    <MastheadBar ref={this.mastheadBarRef} />
                </MastheadWrapper>
                <SpacerPanel />
            </>
        );
    }

    private getImageToLoad() {
        if (window.matchMedia(`(min-width: ${breakpoints.l}px)`).matches) {
            return backgroundLarge;
        } else if (
            window.matchMedia(`(min-width: ${breakpoints.m}px)`).matches
        ) {
            return backgroundMedium;
        } else {
            return backgroundSmall;
        }
    }

    private triggerLogoAnimation = (): void => {
        this.setState({ logoAnimationFlag: true });
    };

    private preloadBackgroundImageForAnimation = () => {
        const backgroundImagePreLoad = new Image();
        backgroundImagePreLoad.src = this.getImageToLoad();
        backgroundImagePreLoad.onload = this.triggerLogoAnimation;
    };

    private showLogoWhenMounted() {
        this.mainLogoRef.current.style.opacity = '1';
    }

    private setPositions = (): void => {
        this.mastheadHeight = this.mastheadRef.current.offsetHeight;
        this.mastheadBarHeight = this.mastheadBarRef.current.offsetHeight;

        /*
            ok so, need the height of just the logo without the wordmark, probably need to use ForwardRefs
            https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-to-dom-components
            for that but theres a bit of learning curve there with typescript in the mix too so just doing
            some raw dom stuff off the current ref for the time being :/
        */

        // We have to reset the transform so we can get its original dimensions, otherwise
        // we get incorrect values when we resize the browser after its scrolled, bit edge case.
        const currentTransformValue = this.mainLogoRef.current.style.transform;
        this.mainLogoRef.current.style.transform = 'none';
        this.logoHeight =
            this.mainLogoRef.current.offsetWidth / LOGO_HEIGHT_ASPECT_RATIO;
        this.logoHeightWithoutWordmark = this.mainLogoRef.current
            .querySelector('svg')
            .getBoundingClientRect().height;

        // set old transform back
        this.mainLogoRef.current.style.transform = currentTransformValue;
        /*****/

        this.deepSeaPanelHeight = this.deepSeaPanelRef.current.offsetHeight;

        this.logoStartPosition =
            this.deepSeaPanelHeight / 2 - this.logoHeight / 2;

        this.logoEndPosition =
            this.mastheadHeight -
            this.mastheadBarHeight / 2 -
            this.logoHeightWithoutWordmark / 2;

        if (
            window.matchMedia &&
            window.matchMedia(
                `(max-width: ${breakpoints.m}px ), (max-height: 900px)`
            ).matches
        ) {
            this.minLogoHeight = MASTHEAD_LOGO_HEIGHTS.s;
        } else {
            this.minLogoHeight = MASTHEAD_LOGO_HEIGHTS.m;
        }
    };

    private setAnimationAsComplete = (): void => {
        this.setState({ animationComplete: true });
    };

    private refreshPositions = (): void => {
        this.setPositions();
        window.requestAnimationFrame(this.updatePositionsBasedOnScroll);
    };

    private getLogoTranslate = (): number => {
        const progress = this.getScrollOffset() / this.logoEndPosition;
        const speed = 1;
        let newLogoTranslate =
            progress > 1
                ? this.logoEndPosition
                : this.logoStartPosition +
                  this.logoEndPosition * progress * speed;

        return newLogoTranslate >= this.logoEndPosition
            ? this.logoEndPosition
            : newLogoTranslate;
    };

    private getPanelTranslate = (): number => {
        return this.getScrollOffset() >= this.deepSeaPanelHeight
            ? this.deepSeaPanelHeight
            : this.getScrollOffset();
    };

    private getLogoScale = (): number => {
        const progress = this.getScrollOffset() / this.logoEndPosition;
        const scaleStop = this.minLogoHeight / this.logoHeightWithoutWordmark;

        const speed = 1.1;

        let newScale = 1 - progress * speed;
        return newScale < scaleStop ? scaleStop : newScale;
    };

    private updatePositionsBasedOnScroll = (): void => {
        this.setState(() => ({
            finished:
                this.getPanelTranslate() >=
                this.mastheadHeight - this.mastheadBarHeight,
            logoScale: this.getLogoScale(),
            logoTranslate: this.getLogoTranslate(),
            panelTranslate: this.getPanelTranslate(),
            scrolling: this.getScrollOffset() > 0
        }));
    };

    private getScrollOffset(): number {
        return window.scrollY || window.pageYOffset;
    }

    private handleScroll = (e: any) => {
        this.setState({ hasScrolled: true });
        window.requestAnimationFrame(this.updatePositionsBasedOnScroll);
    };
}
