import React from 'react';
import styled, { css } from '../../styled-components';
import { Hamburger } from './Hamburger';
import theme from '../../theme';
import { breakpoints, mediaMax, media } from '../../utilities';
import { navigate } from 'gatsby';
import { StylelessLink, Constrain } from '../ui';

enum ANIMATION_STATE {
    idle,
    opening,
    open,
    closing
}

interface INavigationProps extends wmw.IBaseStyledComponentProps {
    reverse: boolean;
}

interface INavigationState {
    jsEnabled: boolean;
    open: boolean;
    animationState: ANIMATION_STATE;
}

export const ToggleMenu = styled.a`
    position: fixed;
    top: 0rem;
    right: 2rem;
    height: 100px;
    z-index: 1000;
    transition: fill 300ms;
    display: inline-block;
    padding: 1rem;
    pointer-events: auto;
    display: flex;
    align-items: center;

    fill: ${({ theme }) => theme.colors.orange.color};

    html.no-js & {
        position: absolute;
    }

    ${media.xl`
        display: none;
    `}

    @media (max-width: ${breakpoints.m}px), (max-height: 900px) {
        height: 70px;
    }
`;

const NoJSCloseMenu = styled(ToggleMenu)`
    fill: ${({ theme }) => theme.colors.deepBlue.color};
    display: none;

    html.no-js {
        display: block;
    }
`;

const GatsbyNavLink = styled(StylelessLink)`
    font-weight: 500;
    text-decoration: none;
    padding: 0.8rem 0;
    transition: all 300ms, text-shadow 600ms;

    background-image: linear-gradient(
        0deg,
        ${({ theme, underlineColor }) => theme.colors[underlineColor].color} 2px,
        hsla(0, 0%, 100%, 0) 0
    );
    background-position: -500px 0px;
    background-repeat: no-repeat;
    pointer-events: auto;

    ${({ theme, reverseColor }) => css`
        ${mediaMax.xl`
            color: ${theme.colors.deepBlue.color};
        `}

        ${media.xl`
            color: ${reverseColor ? theme.colors.deepBlue.color : 'white'};
            text-shadow: ${
                reverseColor
                    ? 'none'
                    : `0px 0px 3px ${theme.colors.deepBlue.color}`
            };
        `}
    `}

    &:hover {
        background-position: 0px 0px;
    }
`;

const NavList = styled.ul<{ animationState: ANIMATION_STATE }>`
    list-style: none;
    margin: 0;
    ${({ animationState }) => mediaMax.xl`
        padding: 10vh 2rem;
        font-size: ${({ theme }) => theme.font.sizing.primary.level4.mobile};
        transform-origin: top;
        opacity: 0;

        ${animationState !== ANIMATION_STATE.idle &&
            css`
                transition: opacity 0ms 500ms;

                li {
                    transition: all 300ms 0ms;

                    &:nth-child(2) {
                        transition-delay: 50ms;
                    }

                    &:nth-child(3) {
                        transition-delay: 100ms;
                    }

                    &:nth-child(4) {
                        transition-delay: 150ms;
                    }

                    &:nth-child(5) {
                        transition-delay: 200ms;
                    }
                }
            `}

        li {
            margin-bottom: 3rem;
            transform: translateY(-5px);
            opacity: 0;
            text-align: center;
        }
    `}

    ${media.xl`
        display: flex;
        height: 100px;
        align-items: center;
        padding: 0 3rem;

        & > li:not(:first-child) {
            margin-left: 3rem;
        }

        & > li:last-child {
            margin-left: auto;
        }

        @media (max-height: 900px) {
            height: 70px;
        }
    `}
`;

const OpenStyles = css`
    /* Override animations from parent when menu open */
    opacity: 1 !important;
    animation: none !important;
    /* transition-delay: 0ms; */

    &:before {
        transform: translate(0, 0) skewX(20.5deg);
        transition-delay: 0ms;
    }

    &:after {
        transform: translate(0, 0) skewX(20.5deg);
        transition-delay: 0ms;
    }

    ul {
        opacity: 1;
        transition: opacity 0ms 0ms;
    }

    li {
        opacity: 1;
        transform: translateY(0);

        transition: all 300ms 300ms;

        &:nth-child(2) {
            transition-delay: 350ms;
        }

        &:nth-child(3) {
            transition-delay: 400ms;
        }

        &:nth-child(4) {
            transition-delay: 450ms;
        }

        &:nth-child(5) {
            transition-delay: 500ms;
        }
    }

    & + ${ToggleMenu} {
        fill: ${({ theme }) => theme.colors.deepBlue.color};
        /* Override animations from parent when menu open */
        opacity: 1 !important;
        animation: none !important;
        /* transition-delay: 0ms; */
    }
`;

const Nav = styled.nav<{ open: boolean; animationState: ANIMATION_STATE }>`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -10;

    ${({ open, animationState }) => mediaMax.xl`
        bottom: -2vh;
        pointer-events: none;
        opacity: 1 !important;

        ${animationState !== ANIMATION_STATE.idle &&
            css`
                transition: opacity 300ms ease-out;
                transition-delay: 650ms;
                z-index: 999;
            `}

        &:before {
            content: '';
            height: 100%;
            width: 200%;
            z-index: -1;
            transform: translate(-38vh, -100%) skewX(20.5deg);
            position: absolute;
            background-color: white;
            transition: all 300ms ease-out;
            transition-delay: 350ms;
        }

        &:after {
            content: '';
            opacity: 1;
            height: 100%;
            width: 100%;
            right: 100%;
            top: 0;
            z-index: -2;
            transform: translate(38vh, 100%) skewX(20.5deg);
            position: absolute;
            background-color: ${({ theme }) => theme.colors.deepBlue.color};
            transition: all 300ms ease-out;
            transition-delay: 350ms;

            ${animationState === ANIMATION_STATE.idle &&
                css`
                    opacity: 0;
                `}
        }

        ${open && OpenStyles}
    `}

    ${media.xl`
        top: 0;
        z-index: 999;
        pointer-events: none;
    `}

    html.no-js & {
        position: absolute;
    }
`;

class Navigation extends React.Component<INavigationProps, INavigationState> {
    public state: INavigationState = {
        jsEnabled: false,
        animationState: ANIMATION_STATE.idle,
        open: false
    };
    private animationTimeout: NodeJS.Timer;

    public componentDidMount() {
        this.setState({ jsEnabled: true });
    }

    public componentWillUnmount() {
        clearTimeout(this.animationTimeout);
    }

    public render() {
        const { className, reverse } = this.props;

        const fillColor =
            this.state.open || reverse ? theme.colors.deepBlue.color : 'white';
        return (
            <div className={className}>
                <Nav
                    id="main-navigation"
                    open={this.state.open}
                    animationState={this.state.animationState}
                >
                    <Constrain type="wide">
                        <NavList animationState={this.state.animationState}>
                            <li>
                                <GatsbyNavLink
                                    to="#what-we-do"
                                    underlineColor="electricBlue"
                                    reverseColor={reverse}
                                    onClick={e => {
                                        this.scrollToElement(e, 'what-we-do');
                                    }}
                                >
                                    What we do
                                </GatsbyNavLink>
                            </li>
                            <li>
                                <GatsbyNavLink
                                    to="#our-work"
                                    underlineColor="orange"
                                    reverseColor={reverse}
                                    onClick={e => {
                                        this.scrollToElement(e, 'our-work');
                                    }}
                                >
                                    Our work
                                </GatsbyNavLink>
                            </li>
                            <li>
                                <GatsbyNavLink
                                    to="#our-insights"
                                    underlineColor="electricBlue"
                                    reverseColor={reverse}
                                    onClick={e => {
                                        this.scrollToElement(e, 'our-insights');
                                    }}
                                >
                                    Insights
                                </GatsbyNavLink>
                            </li>
                            <li>
                                <GatsbyNavLink
                                    to="/why-us/"
                                    underlineColor="green"
                                    reverseColor={reverse}
                                >
                                    Why us?
                                </GatsbyNavLink>
                            </li>

                            <li>
                                <GatsbyNavLink
                                    to="/get-in-touch/"
                                    underlineColor="pink"
                                    reverseColor={reverse}
                                >
                                    Get in touch
                                </GatsbyNavLink>
                            </li>
                        </NavList>
                    </Constrain>
                </Nav>

                <ToggleMenu
                    href="#main-navigation"
                    aria-label="Open main menu"
                    onClick={this.toggleMenu}
                >
                    <Hamburger open={this.state.open} color={fillColor} />
                </ToggleMenu>

                <NoJSCloseMenu href="#" aria-label="Close main menu">
                    <svg
                        width="26px"
                        height="25px"
                        viewBox="0 0 26 25"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g
                            id="Symbols"
                            stroke="none"
                            strokeWidth="1"
                            fillRule="evenodd"
                            fill={theme.colors.deepBlue.color}
                        >
                            <rect
                                id="Rectangle-Copy-3"
                                transform="translate(13.000000, 12.500000) rotate(-315.000000) translate(-13.000000, -12.500000) "
                                x="-3"
                                y="11"
                                width="32"
                                height="3"
                            />
                            <rect
                                id="Rectangle-Copy-4"
                                transform="translate(13.000000, 12.500000) rotate(-225.000000) translate(-13.000000, -12.500000) "
                                x="-3"
                                y="11"
                                width="32"
                                height="3"
                            />
                        </g>
                    </svg>
                </NoJSCloseMenu>
            </div>
        );
    }

    private toggleMenu = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        this.setState(
            {
                open: !this.state.open
            },
            () => {
                const state = this.state.open
                    ? ANIMATION_STATE.opening
                    : ANIMATION_STATE.closing;
                this.setAnimationState(state);

                if (this.animationTimeout) {
                    clearTimeout(this.animationTimeout);
                    this.animationTimeout = null;
                }

                this.animationTimeout = setTimeout(() => {
                    const newState = this.state.open
                        ? ANIMATION_STATE.open
                        : ANIMATION_STATE.idle;
                    this.setAnimationState(newState);
                }, 1000);
            }
        );
    };

    private setAnimationState(state: ANIMATION_STATE) {
        this.setState({
            animationState: state
        });
    }

    private scrollToElement(
        e: React.SyntheticEvent<HTMLAnchorElement>,
        id: string
    ) {
        e.preventDefault();
        const element = document.getElementById(id);

        if (!element) {
            return navigate(`/#${id}`);
        }

        element.scrollIntoView({ behavior: 'smooth' });

        this.setState({
            open: false
        });
    }
}

export default styled(Navigation)`
    #main-navigation:target {
        ${OpenStyles};

        & ~ ${ToggleMenu}:not(${NoJSCloseMenu}) {
            display: none;
        }

        & ~ ${NoJSCloseMenu} {
            display: block;
        }
    }
`;
